import React from "react";

const Vision = () => {
  return (
    <div className="w-full h-full flex justify-center items-center mt-10 px-4 sm:px-8 lg:px-16">
      <img 
        src="/Section.png" 
        alt="Vision Section" 
        className="w-full max-w-4xl h-auto rounded-3xl transform transition-transform duration-700 ease-in-out hover:scale-105 animate-fadeIn"
      />
    </div>
  );
};

export default Vision;
